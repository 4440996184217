import { AxiosResponse } from "axios";
import ApiService from "./ApiService";
import { IActivity } from "../data/activity";

const API_URL = "activities";

class ActivityService {
  public static getActivityById(id): Promise<AxiosResponse> {
    return ApiService.get(API_URL + "/" + id);
  }

  public static getActivityByTopicId(id): Promise<AxiosResponse> {
    return ApiService.get(API_URL + "/search/by-topic/" + id);
  }

  public static createActivity(formData: FormData): Promise<AxiosResponse> {
    return ApiService.post(API_URL, formData);
  }

  public static updateActivity(formData: FormData): Promise<AxiosResponse> {
    return ApiService.post(API_URL+"/edit", formData);
  }

  public static deleteActivity(id): Promise<AxiosResponse> {
    return ApiService.delete(API_URL + "/" + id);
  }
}

export default ActivityService;
